<template>
  <div id="watchlist-page">
    <TitleHelper title="Watcher"></TitleHelper>
    
    <div class="tabs">
        <div v-if="!this.$store.state.watcherJwt && this.currentTab != 'CreateUser'">
            <input v-model="username" placeholder="Username">
            <input type="password" v-model="password" placeholder="Password">
            <button v-on:click="attemptLogin" :disabled="!(username && password)">Login</button>
            <button v-on:click="createUserTab">Create User</button>
        </div>
        <div v-if="this.$store.state.watcherJwt" class="tabs-list">
            <div v-for="t in tabs" :key='t' class="tab" :class="{'selected': currentTab == t}" @click="tabSelect(t)">{{t}}</div>
        </div>
        <div v-if="this.currentTab == 'CreateUser'" class="tabs-list">
        </div>
    </div>

    <div class="tabs warning">
        <span v-if="!errorValue">
            Under development.
        </span>
        <span v-else>
            {{errorValue}}
        </span>
        <span v-if="disabledButtons">
            <span class="thinker"></span>
            <span class="thinker small"></span>
        </span>
    </div>
    
    <div v-if="currentTab == 'Search'">
        <div class="content">
            <input v-model="searchText" @input="debounceSearch" placeholder="Search films and TV">
        </div>
        <div class="response" id="response-zone" @scroll="onScroll">
            <div v-for="d in data" :key="d._id" :id="'item-'+d._id" class="resp-item" :class="{'expand': selected == d._id, 'in-watchlist': imdbIDWatchlist.includes(d.imdbID)}" @click="selectItem(d)">
                <div class="watchlist-flag" v-if="imdbIDWatchlist.includes(d.imdbID)">
                    IN WATCHLIST
                </div>                
                <div class="item-title heavy">
                    <div class="item-type-icon">
                        <span v-if="!isTV(d)" class="type-icon"><font-awesome-icon :icon="filmIcon" /></span>
                        <span v-if="isTV(d)" class="type-icon"><font-awesome-icon :icon="tvIcon" /></span>
                    </div>
                    {{d.title}}
                    <span v-if="d.startYear && d.endYear">({{d.startYear}} - {{d.endYear}})</span>
                    <span v-if="d.startYear && !d.endYear">({{d.startYear}}<span v-if="isTV(d)"> - </span>)</span>
                </div>
                <div :id="'data-content-'+d._id" class="data-content">
                    <div class="poster" v-if="d.poster" style="float: right; height: 150px;">
                        <img :src="d.poster" height="150">
                    </div>
                    <div class="data-item">
                        <span class="heavy">IMDb Categories</span>: 
                        <span v-for='(cat,n) in d.imdbCategories' :key="d._id+'-'+cat">{{cat}}{{(n+1 &lt; d.imdbCategories.length) ? ', ' : ''}}</span>
                    </div>
                    <div class="data-item"><img src="./icons/imdb_icon.png" width="12" height="12"> {{d.imdbRating}} ({{d.imdbVotes}} votes)
                        <span v-if="d.rottenTomatoesRating">&nbsp;<img src="./icons/rott_icon.png" width="12" height="12"> {{d.rottenTomatoesRating}}</span>
                        <span v-if="d.metacriticRating">&nbsp;<img src="./icons/meta_icon.png" width="12" height="12"> {{d.metacriticRating}}</span>
                    </div>
                    <div class="data-item" v-if="d.plot">
                        <span class="heavy">Plot</span>: {{d.plot}}
                    </div>
                    <div class="data-item" v-if="d.duration">
                        <span class="heavy">Duration</span>: {{d.duration}} minutes
                    </div>
                    <div v-if="isTV(d) && tvseries.length > 0" class="data-item" style="margin-top:5px;">
                        <span class="heavy">Seasons:</span>
                    </div>
                    <div v-if="isTV(d) && tvseries.length > 0" style="display:flex; flex-wrap: wrap;">
                        <div v-for="s in tvseries" 
                        :key="'tvs-'+d._id+'-'+s._id" 
                        class="season" 
                        :class="{'selected': s._id == seasonSelected}" 
                        @click.stop="selectSeason(d,s)">
                            {{s._id}}
                        </div>
                    </div>
                    <div v-if="isTV(d) && (seasonSelected || seasonSelected == 0)" class="episodes-list">
                        <div v-for="(e,epno) in episodes" :key="'tve-'+e._id" class="episode" :class="{'alt-episode': epno%2 == 0}">
                            <span v-if="e.episodeNumber">{{e.episodeNumber}} - </span>
                            <span v-else>Special - </span>
                            {{e.title}}
                            <span v-if="e.duration"> - {{e.duration}} minutes</span>
                            <span v-if="e.imdbRating" style="font-size: 8px;"> (IMDb Rating: {{e.imdbRating}} - IMDb Votes: {{e.imdbVotes}})</span>
                        </div>
                    </div>
                </div>

                <div style="text-align: center; min-height: 20px;">
                    <button 
                        class="add-select bottom" 
                        :disabled="disabledButtons"
                        v-if="$store.state.watcherJwt && !isTV(d) && selected == d._id && !imdbIDWatchlist.includes(d.imdbID)"
                        @click="addToUserWatchlist(d, null)">
                            Add to watchlist
                    </button>
                    <button 
                        class="remove-select bottom" 
                        :disabled="disabledButtons"
                        v-if="$store.state.watcherJwt && !isTV(d) && selected == d._id && imdbIDWatchlist.includes(d.imdbID)"
                        @click="removeFromUserWatchlist(d)">
                            Remove from watchlist
                    </button>

                    <button 
                        class="add-select bottom"
                        :disabled="disabledButtons"
                        v-if="$store.state.watcherJwt && isTV(d) && selected == d._id && seasonSelected && !imdbIDWatchlist.includes(d.imdbID)"
                        @click="addToUserWatchlist(d, seasonSelected)">
                            Add Season {{seasonSelected}} to watchlist
                    </button>
                    <button 
                        class="add-select bottom"
                        :disabled="disabledButtons"
                        v-if="$store.state.watcherJwt && isTV(d) && selected == d._id && seasonSelected && imdbIDWatchlist.includes(d.imdbID) && watchlistSeasons[d.imdbID] != seasonSelected"
                        @click="changeSeasonInUserWatchlist(d, seasonSelected)">
                            Change watchlist season from {{watchlistSeasons[d.imdbID]}} to {{seasonSelected}}
                    </button>
                    <button 
                        class="remove-select bottom"
                        :disabled="disabledButtons"
                        v-if="$store.state.watcherJwt && isTV(d) && selected == d._id && seasonSelected && imdbIDWatchlist.includes(d.imdbID) && watchlistSeasons[d.imdbID] == seasonSelected"
                        @click="removeFromUserWatchlist(d)">
                            Remove Season {{seasonSelected}} from watchlist
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="currentTab == 'Watchlist'" style="overflow: scroll; height: calc(100vh - 74px);">
        <div class="item-title heavy" style="margin: 5px; padding: 5px;">
            Watchlist for username: {{username}}
        </div>
        <div v-for="(w, n) in watchlist" :key="'watchlist'+n" class="resp-item" :id="'watchlist-item-'+w._id" :class="{'expand': watchlistSelected == w._id}" @click="selectWatchlistItem(w)">
            <div class="item-title heavy">
                <div class="item-type-icon">
                    <span v-if="!isTV(w)" class="type-icon"><font-awesome-icon :icon="filmIcon" /></span>
                    <span v-if="isTV(w)" class="type-icon"><font-awesome-icon :icon="tvIcon" /></span>
                </div>
                {{w.title}}
            </div>
            <div :id="'watchlist-data-content-'+w._id" class="watchlist-data-content">
                <div class="poster" v-if="w.poster" style="float: right; height: 150px;">
                    <img :src="w.poster" height="120">
                </div>
                <div class="data-item">
                    <span class="heavy">IMDb Categories</span>: 
                    <span v-for='(cat,n) in w.imdbCategories' :key="w._id+'-'+cat">{{cat}}{{(n+1 &lt; w.imdbCategories.length) ? ', ' : ''}}</span>
                </div>
                <div class="data-item" v-if="!w.episode">
                    <span v-if="w.imdbRating"><img src="./icons/imdb_icon.png" width="12" height="12"> {{w.imdbRating}} ({{w.imdbVotes}} votes)</span>
                    <span v-if="w.rottenTomatoesRating">&nbsp;<img src="./icons/rott_icon.png" width="12" height="12"> {{w.rottenTomatoesRating}}</span>
                    <span v-if="w.metacriticRating">&nbsp;<img src="./icons/meta_icon.png" width="12" height="12"> {{w.metacriticRating}}</span>
                </div>
                <div class="data-item" v-if="w.plot && !w.episode">
                    <span class="heavy">Plot</span>: {{w.plot}}
                </div>
                <div class="data-item">
                    <div class="data-item" v-if="w.episode">
                        <span class="heavy">Next episode</span>:
                    </div>
                    <div class="data-item" v-if="w.episode">
                        <span class="heavy">Season</span>: {{w.season}}, <span class="heavy">Episode</span>: {{w.episode}} of {{w.finalEpisode}}
                    </div>
                    <div class="data-item" v-if="w.episode">
                        <span class="heavy">Episode Title</span>: {{w.episodeTitle}}
                    </div>
                    <span class="heavy">Duration
                        <span v-if="w.episode"> of episode</span>    
                    </span>: 
                    <span>{{w.duration}} minutes</span>
                    <div class="data-item" v-if="w.plot && w.episode">
                        <span class="heavy">Plot of episode</span>: {{w.plot}}
                    </div>
                    <div class="data-item" v-if="w.episode">
                        <span v-if="w.imdbRating"><img src="./icons/imdb_icon.png" width="12" height="12"> {{w.imdbRating}} ({{w.imdbVotes}} votes)</span>
                        <span v-if="w.rottenTomatoesRating">&nbsp;<img src="./icons/rott_icon.png" width="12" height="12"> {{w.rottenTomatoesRating}}</span>
                        <span v-if="w.metacriticRating">&nbsp;<img src="./icons/meta_icon.png" width="12" height="12"> {{w.metacriticRating}}</span>
                    </div>
                </div>
            </div>

            <div style="text-align: center; min-height: 20px;">
                <button 
                    class="add-select bottom" 
                    v-if="watchlistSelected == w._id && w.episodeID && w.episode != w.finalEpisode"
                    style="margin-right: 20px;"
                    @click="nextEpisode(w)"
                    :disabled="disabledButtons"
                    >
                        Next episode
                </button>
                <button 
                    class="remove-select bottom" 
                    v-if="watchlistSelected == w._id"
                    @click="removeFromUserWatchlist(w)"
                    :disabled="disabledButtons">
                        Remove from watchlist
                </button>
            </div>
        </div>
    </div>

    <div v-if="currentTab == 'Admin'">
        <button @click="logOff" style="margin: 15px;">Log out</button>
    </div>

    <div v-if="currentTab == 'CreateUser'" class="creator">
        <div style="margin: 15px;">
            <input v-model="username" placeholder="Username">
        </div>
        <div style="margin: 15px;">
            <input v-model="name" placeholder="What should I call you?">
        </div>
        <div style="margin: 15px;">
            <input v-model="email" type="email" placeholder="Email address">
            <span v-if="email && !emailValid" class="not-valid">Not valid</span>
        </div>
        <div style="margin: 15px;">
            <input v-model="password" type="password" placeholder="Password">
        </div>

        <button @click="createUser" style="margin: 15px;" :disabled="!(username && email && password && name && emailValid)">Create User</button>
        
        <button @click="logOff" style="margin: 15px;" :disabled="!(username && password && $store.state.watcherJwt)">Log out</button>
    
        <div style="margin: 15px; color: red;" v-if="errorValue">
            {{errorValue}}
        </div>

    </div>

  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';
import { faFilm, faTv } from '@fortawesome/free-solid-svg-icons'
const _ = require('lodash');

export default {
  name: "Watcher",
  beforeCreate: function() {
    document.body.className = 'watcher';
  },
  data() {
    return {
        errorValue: null,
        values: config,
        searchText: null,
        data: [],
        offset: 0,
        scrollable: true,
        selected: null,
        watchlistSelected: null,
        tvseries: [],
        episodes: [],
        seasonSelected: null,
        name: null,
        username: null,
        password: null,
        currentTab: 'Search',
        tabs: ['Search','Watchlist','Admin'],
        watchlist: [],
        email: null,
        disabledButtons: false,
        responseStore: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  components: {
    TitleHelper,
  },
  methods: {
    attemptLogin: function() {
      this.errorValue = null;
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = { username: this.username, password: this.password };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/login-user";
      axios
        .post(url, body, config)
        .then(response => {
          this.$store.state.watcherJwt = response["data"];
          if (this.selected) {
              this.resetHeight('data-content-'+this.selected, 'item-'+this.selected, 100, this.$store.state.watcherJwt);
          }
          this.getWatchlist();
        })
        .catch(error => {
          this.errorValue = error.response.request.response;
        });
    },
    createUser: function() {
        this.errorValue = null;
        var config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        var body = { username: this.username, name: this.name, password: this.password, email: this.email };
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/create-user";
        axios
            .post(url, body, config)
            .then(response => {
                this.responseStore = response;
                this.errorValue = "Check your email."
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
            });
    }, 
    createUserTab: function() {
        this.currentTab = 'CreateUser';
    },
    tabSelect: function(tab) {
        this.currentTab = tab;
        if (this.selected) {
            this.resetHeight('data-content-'+this.selected, 'item-'+this.selected, 100, this.$store.state.watcherJwt);
        }
        if (this.watchlistSelected) {
            this.resetHeight('watchlist-data-content-'+this.watchlistSelected, 'watchlist-item-'+this.watchlistSelected, 100, true);
        }
    },
    logOff: function() {
        this.$store.state.watcherJwt = null;
        this.username = null;
        this.password = null;
        this.currentTab = 'Search';
        if (this.selected) {
            this.resetHeight('data-content-'+this.selected, 'item-'+this.selected, 100, null);
        }
    },
    addToUserWatchlist: function(d, season) {
        this.disabledButtons = true;
        var config = {
            headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer: " + this.$store.state.watcherJwt,
            }
        };
        var body = {
            username: this.username
        }
        for (let [k,v] of Object.entries(d)) {
            if (!['_id', 'proximity','order'].includes(k)) {
                body[k] = v;
            }
        }
        if (season) {
            body['season'] = season;
        }
        let url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/add-to-watchlist";
            axios
                .post(url, body, config)
                .then(response => {
                    this.responseStore = response;
                    this.getWatchlist();
                })
                .catch(error => {
                    this.errorValue = error.response.request.response;
                    this.disabledButtons = false;
                });
    },
    changeSeasonInUserWatchlist: function(d, season) {
        this.disabledButtons = true;
        var config = {
            headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer: " + this.$store.state.watcherJwt,
            }
        };
        var body = {
            username: this.username,
            imdbID: d.imdbID,
            season: season,
        }
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/change-season-watchlist";
        axios
            .put(url, body, config)
            .then(response => {
                this.responseStore = response;
                this.getWatchlist();
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
                this.disabledButtons = false;
            });
    },
    removeFromUserWatchlist: function(d) {
        this.disabledButtons = true;
        if (this.watchlistSelected == d._id) {
            let el = document.getElementById('watchlist-item-'+this.watchlistSelected);
            if (el) {
                el.style.height = '25px';
            }
        }
        var config = {
            headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer: " + this.$store.state.watcherJwt,
            }
        };
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/remove-from-watchlist?username=" + this.username + "&imdbID=" + d.imdbID;
        axios
            .delete(url, config)
            .then(response => {
                this.responseStore = response;
                this.watchlistSelected = null;
                setTimeout(() => {
                    this.getWatchlist();
                }, 500)
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
                this.disabledButtons = false;
            });
    },
    getWatchlist: function() {
        var config = {
            headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer: " + this.$store.state.watcherJwt
            }
        };
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/my-watchlist?username=" + this.username;
        axios
            .get(url, config)
            .then(response => {
                this.watchlist = response['data'];
                this.disabledButtons = false;
                this.$nextTick(() => {
                    this.resetHeight('watchlist-data-content-'+this.watchlistSelected, 'watchlist-item-'+this.watchlistSelected, 100, true);
                });
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
            });
    },
    nextEpisode: function(item) {
        this.disabledButtons = true;
        let config = {
            headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer: " + this.$store.state.watcherJwt,
            }
        };
        for (let w of this.watchlist) {
            let el = document.getElementById('watchlist-item-'+w._id);
            if (el) {
                el.style.height = '25px'
            }
        }
        let body = {'update': item}
        let url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/next-episode";
        axios.put(url, body, config)
            .then(response => {
                this.responseStore = response;
                this.getWatchlist();
                this.$nextTick(() => {
                    this.resetHeight('data-content-'+item._id, 'item-'+item._id, 100, this.$store.state.watcherJwt);
                });
            });
    },
    updateData: function(oldData) {
        var body = {'oldData': oldData};
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/update/" + oldData.imdbID;
        axios
            .put(url, body)
            .then(response => {
                let output = []
                for (let d of this.data) {
                    if (d._id == response['data']['_id']) {
                        output.push(response['data']);
                    } else {
                        output.push(d);
                    }
                }
                this.data = output;
                this.$nextTick(() => {
                    this.resetHeight('data-content-'+oldData._id, 'item-'+oldData._id, 100, this.$store.state.watcherJwt);
                });
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
            })
    },
    isTV: function(d) {
        return (!['movie','tvMovie'].includes(d.imdbType));
    },
    onScroll: function({ target: { scrollTop, clientHeight, scrollHeight }}) {
    if (scrollTop + clientHeight >= scrollHeight && this.data && this.scrollable) {
        this.offset += 30;
        this.doSearch();
        }
    },
    doSearch: function() {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      let string = '';
      if (this.offset) {
          string += '&offset='+(this.offset.toString())
      }
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher?search=" + this.searchText + string;
      axios
        .get(url,body,config)
        .then(response => {
            if (response['data'].length < 30) {
                this.scrollable = false;
            }
            if (this.offset > 0) {
                for (let d of response['data']) {
                    this.data.push(d);
                }
            } else {
                this.data = response['data'];
            }
            
        })
        .catch(error => {
            this.errorValue = error.response.request.response;
        })
    },
    resetHeight: function(dataElement, itemElement, delay, jwt) {
        setTimeout(function() {
            let el = document.getElementById(dataElement);
            let height = 0;
            if (el) {
                height = el.offsetHeight;
            }
            el = document.getElementById(itemElement);
            let extra = 40;
            if (jwt) {
                extra += 20;
            }
            if (el) {
                el.style.height = (height+extra).toString()+'px';
            }
        }, delay);
    },
    getTVSeries: function(series) {
      var config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var body = {  };
      var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/tvseries/" + series.imdbID;
      axios
        .get(url,body,config)
        .then(response => {
            this.tvseries = response['data'];
            this.$nextTick(() => {
                this.resetHeight('data-content-'+series._id, 'item-'+series._id, 100, this.$store.state.watcherJwt);
            });
        })
        .catch(error => {
            this.errorValue = error.response.request.response;
        })
    },
    selectSeason: function(show,series) {
      if (this.seasonSelected != series._id){
        this.seasonSelected = series._id;
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/watcher/tvseries/" + show.imdbID + '/' + series._id;
        axios
            .get(url,body,config)
            .then(response => {
                this.episodes = response['data'];
                this.$nextTick(() => {
                    this.resetHeight('data-content-'+show._id, 'item-'+show._id, 100, this.$store.state.watcherJwt);
                });
            })
            .catch(error => {
                this.errorValue = error.response.request.response;
            })
      } else {
            this.seasonSelected = null;
            this.episodes = null;
            this.$nextTick(() => {
                this.resetHeight('data-content-'+show._id, 'item-'+show._id, 100, this.$store.state.watcherJwt);
            });
      }
    },
    selectWatchlistItem: function(item) {
        if (this.watchlistSelected != item._id) {
            let el = document.getElementById('watchlist-item-'+this.watchlistSelected);
            if (el) {
                el.style.height = '25px';
            }
            this.resetHeight('watchlist-data-content-'+item._id, 'watchlist-item-'+item._id, 100, true);
            this.watchlistSelected = item._id;
        }
    },
    selectItem: function(item) {
        if (this.selected != item._id) {
            let el = document.getElementById('item-'+this.selected);
            if (el) {
                el.style.height = '25px';
            }
            this.tvseries = [];
            this.episodes = [];
            this.seasonSelected = null;
            if (!item.poster) {
                this.updateData(item);
            }
            if (this.isTV(item)) {
                this.getTVSeries(item);
            } else {
                this.$nextTick(() => {
                    this.resetHeight('data-content-'+item._id, 'item-'+item._id, 100, this.$store.state.watcherJwt);
                });
            }
            this.selected = item._id;
        }
    },
    onResize() {
      if (this.selected) {
        this.resetHeight('data-content-'+this.selected, 'item-'+this.selected, 50, this.$store.state.watcherJwt);
      }
      if (this.watchlistSelected) {
        this.resetHeight('watchlist-data-content-'+this.watchlistSelected, 'watchlist-item-'+this.watchlistSelected, 50, true);
      }
    },
  },
  created() {
    this.debounceSearch = _.debounce(() => {
        this.offset = 0;
        this.data = [];
        this.scrollable = true;
        this.selected = null;
        this.episodes = [];
        this.doSearch();
    }, 200);
  },
  computed: {
    emailValid() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.email) {
            return re.test(String(this.email).toLowerCase());
        } else {
            return false;
        }
    },
    filmIcon() {
        return faFilm;
    },
    tvIcon() {
        return faTv;
    },
    imdbIDWatchlist() {
        if (this.watchlist.length > 0) {
            let output = [];
            for (let w of this.watchlist) {
                if (!w.watched) {
                    output.push(w.imdbID);
                }
            }
            return output;
        } else {
            return [];
        }
    },
    watchlistSeasons() {
        if (this.watchlist.length > 0) {
            let output = {};
            for (let w of this.watchlist) {
                if (w.season && !w.watched) {
                    output[w.imdbID] = w.season;
                }
            }
            return output;
        } else {
            return {};
        }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@font-face {
    font-family: 'bould-bold';
    src: url('fonts/bould-semibold-webfont.woff2') format('woff2');
    src: url('fonts/bould-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'bould-light';
    src: url('fonts/bould-light-webfont.woff2') format('woff2');
    src: url('fonts/bould-light-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

div, input {
    font-family: "bould-light", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

.heavy {
    font-family: "bould-bold", Helvetica, Arial, sans-serif;
}

button {
    font-family: "bould-light", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

div.content {
    padding: 5px;
    margin: 3px;
}

input {
    padding: 5px;
    margin: 3px;
}

div.response {
    overflow: scroll;
    /* height: calc(100vh - 106px); */
    height: calc(100vh - 136px);
}

div.item-title {
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100vw - 16px);
    display: block;
    overflow: hidden;
}

div.item-type-icon {
    padding: 3px;
    margin-right: 5px;
    margin-top: -3px;
    border-radius: 4px;
    float: left;
    min-width: 25px;
    text-align: center;
}

div.resp-item {
    background-color: #b2ddb2;
    border-radius: 8px;
    padding: 7px;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    height: 25px;
    margin: 3px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

div.resp-item:hover {
    background-color: #92bb92;
}

div.resp-item.in-watchlist {
    background-color: #a2cda2;
    color: black;
}

div.resp-item.expand {
    background-color: #328832;
    color: white;
    cursor: default;
}

.watchlist-flag {
    font-size: 8px !important;
    background-color: transparent;
    position: absolute;
    padding: 2px;
    top: 1px;
    right: 5px;
}

div.data-content, div.watchlist-data-content {
    min-height: 150px;
}

div.data-item {
    font-size: 12px;
}

div.season {
    font-size: 16px;
    display: inline;
    padding: 0 2px;
    background-color: darkgreen;
    margin: 2px;
    width: 18px;
    border-radius: 3px;
    text-align: center;
}

div.season.selected {
    background-color: #aadd88;
    color: black;
}

div.season:hover {
    color: grey;
}

div.episodes-list {
    overflow: scroll;
    display: table;
    width: 100%;
    margin-top: 5px;
}

div.episode {
    background-color: #aadd88;
    color: black;
    font-size: 12px;
    padding: 0 3px;
}

div.alt-episode {
    background-color: #bbee99;
}

span.type-icon {
    width: 50px;
    font-size: 20px;
}

div.tabs {
    background-color: #d9ffd9;
    min-height:46px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    display: flex;
    max-height: 46px;
    overflow: hidden;
}

div.tabs.warning {
    background-color: red;
    color: yellow;
    text-align: center;
    font-size: 15px;
    display: block;
    min-height: 26px;
    max-height: 26px;
    padding-top:2px;
}

div.tabs-list {
    width: 100%;
    display: flex;
}

div.tabs input {
    width: calc(20vw);
    min-width: 92px;
}

div.tabs button {
    margin: 4px;
}

div.tab {
    width: 33.3%;
    margin: 3px 3px 0 3px;
    border-width: 1px 1px 0 1px;
    border-color: black;
    border-style: solid;
    border-radius: 8px 8px 0 0;
    text-align: center;
    padding: 8px 0 0 0;
    cursor: pointer;
    background-color: #b2ddb2;
}

div.tab.selected {
    background-color: #328832;
}

button.bottom {
    border: none;
    border-radius: 4px;
    font-size: 10px;
    background-color: #126612;
    color: white;
    position:relative;
    top: -3px;
    cursor: pointer;
}

button[disabled] {
    background-color: #bbb !important;
    color: #888;
    border: 2px solid #aaa;
    border-radius: 3px;
}

button[disabled].bottom {
    border: none;
}

button.add-select {
    background-color: #126612;
}

button.remove-select {
    background-color: #661212;
}

div.creator input {
    width: calc(100% - 30px);
}

div.creator span.not-valid {
    position: absolute;
    font-size: 10px;
    right: 32px;
    padding-top: 5px;
    color: red;
}

span.thinker {
    border-style: none solid solid solid;
    border-radius: 20px;
    position: absolute;
    right: 2px;
    height: 18px;
    width: 18px;
    border-width: 1.5px;
    margin-top: 1px;

    -webkit-animation-name: spin;
    -webkit-animation-duration: 930ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;

    -moz-animation-name: spin;
    -moz-animation-duration: 930ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;

    -ms-animation-name: spin;
    -ms-animation-duration: 930ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: ease-in-out;

    animation-name: spin;
    animation-duration: 930ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@-ms-keyframes spin { 
    from { 
        -ms-transform: rotate(0deg); 
    } to { 
        -ms-transform: rotate(360deg); 
    }
}
@-moz-keyframes spin { 
    from { 
        -moz-transform: rotate(0deg); 
    } to { 
        -moz-transform: rotate(360deg); 
    }
}
@-webkit-keyframes spin { 
    from { 
        -webkit-transform: rotate(0deg); 
    } to { 
        -webkit-transform: rotate(360deg); 
    }
}
@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}

@-ms-keyframes spinback { 
    from { 
        -ms-transform: rotate(360deg); 
    } to { 
        -ms-transform: rotate(0deg); 
    }
}
@-moz-keyframes spinback { 
    from { 
        -moz-transform: rotate(360deg); 
    } to { 
        -moz-transform: rotate(0deg); 
    }
}
@-webkit-keyframes spinback { 
    from { 
        -webkit-transform: rotate(360deg); 
    } to { 
        -webkit-transform: rotate(0deg); 
    }
}
@keyframes spinback { 
    from { 
        transform: rotate(360deg); 
    } to { 
        transform: rotate(0deg); 
    }
}

span.thinker.small {
    border-style: solid solid none solid;
    right: 6px;
    height: 10px;
    width: 10px;
    border-width: 1.5px;
    margin-top: 5px;
    -webkit-animation-name: spinback;
    -webkit-animation-duration: 1120ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;

    -moz-animation-name: spinback;
    -moz-animation-duration: 1120ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;

    -ms-animation-name: spinback;
    -ms-animation-duration: 1120ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: ease-in-out;

    animation-name: spinback;
    animation-duration: 1120ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

</style>

